import { checkSegmentacion, checkSalaPoll, removePopUp, exitFullScreen } from "./Realtime";
import { PopUp } from "../Notification/PopUp";
import { notify } from "../Notification/Notify";
import { is_iOS } from "../iOSCompatibility";
import { dictionary } from "../Dictionary/main";
import { csrf } from "../variables";

export const bindPolls = async (channel, info) => {
    channel.bind('polls', (data) => {
        if (checkSegmentacion(data, info) && checkSalaPoll(data, info)) {
            if (data.activo == 0) {
                removePopUp();
            } else if (data.activo == 1) {
                removePopUp()
                        setTimeout(() => {
                            renderPoll(data, info.evento.lang)
                        }, 400)
            }
        }
    })
}



const renderPoll = (data, lang) => {
    exitFullScreen();
    let question_language = 'pregunta';
    let answer_language = 'respuesta';
    if (lang !== 'ES') {
        question_language += `${question_language}_${lang}`;
        answer_language = `${answer_language}_${lang}`;
    }
    const embedContent = new PopUp().new();
    embedContent.classList.add('poll');


    if (data.imagen != '') {
        const imgRow = document.createElement('div');
        imgRow.classList.add('row');
        const imgCol = document.createElement('div');
        imgCol.classList.add('col-12');
        const img = document.createElement('img');
        img.style.width = "100%";
        img.style.height = "100%";
        img.src = data.imagen;
        imgRow.append(imgCol);
        imgCol.append(img);
        embedContent.append(imgRow);
    }

    const questionRow = document.createElement('div');
    questionRow.classList.add('row', 'pregunta');

    const questionCol = document.createElement('div');
    questionCol.classList.add('col-12');

    const question = document.createElement('p');
    if (data[question_language] === undefined) {
        question.innerHTML = data['pregunta'];
    } else {
        question.innerHTML = data[question_language];
    }


    embedContent.append(questionRow);
    questionRow.append(questionCol);
    questionCol.append(question);

    if (parseInt(data['tipo_pregunta']) === 0) {
        const answerRow = document.createElement('div');
        answerRow.classList.add('row', 'respuesta');
        const answerCol = document.createElement('div');
        answerCol.classList.add('col-12');
        const answerInput = document.createElement('textarea');
        answerInput.name = 'respuesta';
        answerInput.id = 'respuesta_abierta';

        embedContent.append(answerRow);
        answerRow.append(answerCol);
        answerCol.append(answerInput);
    } else if (data['answers'].length >= 1) {
        data['answers'].forEach(answer => {
            const answerRow = document.createElement('div');
            answerRow.classList.add('row', 'respuesta');

            const answerCol = document.createElement('div');
            answerCol.classList.add('col-12', 'polls-radio-toolbar');

            const answerInput = document.createElement('input');
            answerInput.name = 'respuesta';
            answerInput.id = answer.id;

            switch (parseInt(data['tipo_pregunta'])) {
                case 1:
                    answerInput.classList.add('polls-form-check-input', 'encuesta-radiobutton');
                    answerInput.type = 'radio';
                    break;
                case 2:

                    answerInput.classList.add('polls-form-check-input', 'encuesta-checkbox');
                    answerInput.type = 'checkbox';
                    break;
            }

            const answerLabel = document.createElement('label');
            answerLabel.htmlFor = answer.id;
            answerLabel.classList.add('polls-form-check-label');

            if (answer[answer_language] === undefined) {
                answerLabel.innerText = answer['respuesta'];
            } else {
                answerLabel.innerText = answer[answer_language];
            }

            answerCol.append(answerInput);
            answerCol.append(answerLabel);
            answerRow.append(answerCol);

            embedContent.append(answerRow);
        });
    }
    const buttonsRow = document.createElement('div');
    buttonsRow.classList.add('row', 'justify-content-end');

    if (data['id_siguiente'] != 0) {
        const next = document.createElement('button');
        next.classList.add('btn', 'btn-plain', 'mr-4');
        next.id = 'btn-next';
        next.dataset.role = 'button';
        next.innerText = dictionary[lang]['omitir'];
        buttonsRow.append(next);
        next.addEventListener('click', () => {
            loadNextPoll(data['id_siguiente'], lang);
        });
    }

    const submit = document.createElement('button');
    submit.classList.add('btn', 'primary');
    submit.id = 'btn-encuesta';
    submit.dataset.role = 'button';
    submit.innerText = dictionary[lang]['enviarRespuesta'];
    submit.addEventListener('click', () => {
        submitPoll(data.id, data['tipo_pregunta'], lang);
        if (data['id_siguiente'] != 0) loadNextPoll(data['id_siguiente'], lang);

    });

    embedContent.append(buttonsRow);
    buttonsRow.append(submit);

    if (is_iOS()) {
        document.querySelector('#velo').classList.add('iphone')
    }

    const timer = document.createElement('input');
    timer.type = 'hidden';
    timer.id = 'timer';
    timer.value = 0;

    startTimer(timer);

    embedContent.append(timer);
}

const startTimer = (element) => {
    let startTime = Date.now();
    setInterval(() => {
        element.value = Date.now() - startTime;
    })
}

const loadNextPoll = async (idPoll, lang) => {
    let data = await fetchPoll(idPoll);
    removePopUp()
            setTimeout(() => {
                renderPoll(data, lang);
            }, 400)
}

const fetchPoll = async (idPoll) => {
    var myHeaders = new Headers();
    myHeaders.append('X-CSRF-Token', csrf);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    }

    let poll = await fetch(`API/get.php?polls=get_poll&id=${idPoll}`, requestOptions)
    poll = await poll.json()
    return poll;
}

const submitPoll = async (id, typeQuestion, lang) => {
    const answers = document.querySelectorAll('.poll input[name="respuesta"]:checked');
    let answersArr = [];
    answers.forEach(element => {
        answersArr.push(element.id);
    })

    const time = document.querySelector('.poll #timer').value;

    let myHeaders = new Headers();
    myHeaders.append('X-CSRF-Token', csrf);

    let data = new FormData();
    data.append('answers', answersArr);
    data.append('time', time);
    data.append('id', id);
    if (typeQuestion == 0) {
        data.append('respuesta_abierta', document.querySelector('#respuesta_abierta').value)
    }

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: data
    }

    let response = await fetch('API/post.php?polls=submit', requestOptions);
    try {
        response = await response.json();
        switch (response) {
            case 'incorrect':
                notify(dictionary[lang]['respuestaIncorrecta']);
                break;
            case 'some correct':
                if (typeQuestion == 1) notify(dictionary[lang]['respuestaCorrecta']);
                else notify(dictionary[lang]['respuestaIncorrecta']);
                break;
            case 'correct':
                notify(dictionary[lang]['respuestaCorrecta']);
                break;
            case 'saved':
                notify(dictionary[lang]['respuestaGuardada']);
                break;
            default:
                notify(dictionary[lang]['respuestaGuardada']);
                break;
        }
        removePopUp();
    } catch (error) {
        notify(dictionary[lang]['error']);
        response = await response.text();
        console.error(error);
        console.log(response);
    }
}