import { checkSala } from "./Realtime";
import { csrf } from "../variables";

export const initEmociones = async (channel, info) => {
    if(document.querySelector('#social_icons')){
        addEventListenerEmociones();
        await bindEmociones(channel, info);
    }
}

const bindEmociones = async (channel, info) => {
    channel.bind('emociones', (data) => {
        if (checkSala(data, info)) {
            plusOneEmocion(data['tipo_emocion'])
        }
    })
}

const addEventListenerEmociones = () => {
    let emociones = document.querySelectorAll('[data-action="emociones"');
    emociones.forEach(emocion => {
        emocion.addEventListener('click', () => {
            sendEmocion(emocion)
        })

    })
}

const sendEmocion = (emocion) => {

    let id = emocion.id;
    let icono = emocion.dataset.icono;

    let data = new FormData();
    data.append('id', id);
    data.append('icono', icono);


    let myHeaders = new Headers();
    myHeaders.append('X-CSRF-Token', csrf);

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: data
    }

    fetch('API/post.php?emociones=submit', requestOptions)
        .then(response => response.json())


    emocion.classList.add('activa');
    setTimeout(() => {
        emocion.classList.add('opacity-0');
        emocion.classList.remove('activa');
        setTimeout(() => {
            emocion.classList.remove('opacity-0');
        }, 500)

    }, 2000)

}

const plusOneEmocion = (emocion) => {
    let emocionText = document.querySelector(`small.emociones[data-id='${emocion}']`);
    let emocionTextValue = parseInt(emocionText.textContent);
    emocionText.textContent = emocionTextValue + 1;
}