/**
 * Funciones y variables relacionadas con el registro de la actividad del usuario
 * @var cadaCuantosMinutosSalvaPresencia int
 * @var time int
 * @var intervaloRegistrarActividad null/string
 * 
 */

const cadaCuantosMinutosSalvaPresencia = 5;
let time = (cadaCuantosMinutosSalvaPresencia*1000)*60
export let intervaloRegistrarActividad = null;

/**
 * Invoca la petición AJAX que graba en la base de datos el tiempo 
 * @returns string. ID de intervalo para poder cancelarlo a posteriori
 */

export const registrarActividad = () => {
    if(intervaloRegistrarActividad !== null) {
        clearInterval(intervaloRegistrarActividad);
    }
    return setInterval(registrarActividadAjax, time);
}

const registrarActividadAjax = () => {
    const xhr = new XMLHttpRequest();
        const datos = new FormData();
        datos.append('actividad', 'actividad');
    
        xhr.open('POST', 'includes/functions/registro_actividad.php');
    
        xhr.onload = function () {
            if (this.status === 200) {
                let response = JSON.parse(this.responseText);
            }
        }
    
        xhr.send(datos);
}

