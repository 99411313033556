/**
 * Agrupa los event listeners que hay a modo general
 */

const eventListeners = () => {
    closePopUp();
    captureFirstInteraction();
    showSchedule();
}

const closePopUp = () => {
    document.addEventListener('click', (e)=>{
        if (e.target.dataset.action == 'close-popup') {
            document.querySelector('#velo').remove();
            body.style.overflow = 'auto';
        }
    })
}

const captureFirstInteraction = () => {
    document.addEventListener('click', ()=>{
        userHasInteracted = true;
    })
}

const showSchedule = () => {
    document.querySelectorAll('[data-action="schedule"]').forEach(scheduleButton => {
        scheduleButton.addEventListener('click', () => {
            let url = scheduleButton.dataset.agenda;
            window.open(url, '_blank');
        })
    })
}

export {eventListeners}