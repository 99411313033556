export const notify = (messageString, titleString = '') => {
    const notify = document.createElement('div');
    notify.classList.add('notify', 'container-fluid');

    const titleRow = document.createElement('div');
    titleRow.classList.add('row');
    const titleCol = document.createElement('div');
    titleCol.classList.add('col');
    const title = document.createElement('p');
    title.classList.add('notify-title');
    title.innerHTML = titleString;
    titleRow.append(titleCol);
    titleCol.append(title);
    notify.append(titleRow);

    const messageRow = document.createElement('div');
    messageRow.classList.add('row');
    const messageCol = document.createElement('div');
    messageCol.classList.add('col');
    const message = document.createElement('p');
    message.classList.add('notify-message');
    message.innerText = messageString;

    const iconCol = document.createElement('div');
    iconCol.classList.add('col-4');
    const icon = document.createElement('p');
    icon.classList.add('notify-icon');


    messageRow.append(icon);
    messageRow.append(messageCol);
    messageCol.append(message);
    notify.append(messageRow);

    body.append(notify);
    setTimeout(() => {
        notify.classList.add('in');
        setTimeout(() => {
            notify.classList.remove('in')
            setTimeout(() => {
                notify.remove();
            }, 300)
        }, 2000)
    }, 10)

    notify.addEventListener('click', () => {
        notify.remove();
    })
}
