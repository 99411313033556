import { is_iOS } from "../iOSCompatibility";


export const loadMillicast = async () => {
    const videoMillicast = document.querySelector('#millicast');

    if (videoMillicast){
        subscribe();
    } 
}



