import { dictionary } from "../Dictionary/main";
import { decryptUserInfo, checkIfInputIsRequiredAndIsEmpty, resetInput, getButton, contarCaracteres } from "../main";
import { checkSala } from "./Realtime";
import { PopUpAlert } from "../Notification/PopUpAlert";



export const initPreguntasAlPonente = async(channel, info) => {
    bindPreguntasAlPonente(channel, info);
    sortByVotes();
    contarCaracteres('question');
}

const bindPreguntasAlPonente = async (channel, info) => {
    channel.bind('PreguntasAlPonente', (data) => {
        const Q4U_box = document.querySelector('#Q4Y-questions-box');
        if (!Q4U_box) return;
        if (checkSala(data, info)) {
            if (isQuestionAccepted(data)) {
                switch (data.action) {
                    case 'sumar':
                        actualizarVotos(data)
                        break;
                    case 'restar':
                        actualizarVotos(data)
                        break;
                    case 'updateStatus':
                        nuevaPreguntaAceptada(data)
                        break;
                }
            } else {
                if (data.action === 'updateStatus') quitarPreguntaContestada(data)
            }
        }
    })
}

const isQuestionAccepted = (data) => {
    let {
        status_q
    } = data;
    status_q = parseInt(status_q);
    if (status_q === 3) {
        return true;
    } else if (status_q === 2 || status_q === 4) {
        return false;
    }
}

const actualizarVotos = (data) => {
    const {
        id,
        action
    } = data;
    const Q4U_box = document.querySelector('#Q4Y-questions-box');
    if (Q4U_box) {
        let votos = document.querySelector(`.Q4Y-votes[data-id-pregunta="${id}"] .numberofvotes`);
        if (action === 'sumar') {
            votos.textContent = parseInt(votos.textContent) + 1;
        } else if (action === 'restar') {
            votos.textContent = parseInt(votos.textContent) - 1;
        }
        sortByVotes();
    }
}

const sortByVotes = () => {
    let question = document.querySelectorAll('.Q4Y-question-row');
    let questionArr = new Array();
    for (let i = 0; i < question.length; i++) {
        questionArr.push(question[i]);
        question[i].remove()
    }
    questionArr.sort(function (a, b) {
        if (parseInt(a.querySelector('.numberofvotes').innerHTML) === parseInt(b.querySelector('.numberofvotes').innerHTML)) {
            return 0;
        }
        if (parseInt(a.querySelector('.numberofvotes').innerHTML) > parseInt(b.querySelector('.numberofvotes').innerHTML)) {
            return -1;
        } else {
            return 1;
        }
    });
    for (let i = 0; i < question.length; i++) {
        const Q4U_box = document.querySelector('#Q4Y-questions-box');
        Q4U_box.appendChild(questionArr[i]);
    }

}

const quitarPreguntaContestada = (data) => {
    const {
        id
    } = data;
    let pregunta = document.querySelector(`.Q4Y-votes [data-id-pregunta="${id}"]`);
    if (pregunta) {
        pregunta.parentNode.remove();
    }
}

const nuevaPreguntaAceptada = (data) => {
    const {
        votes,
        speaker,
        question,
        id
    } = data;
    const pregunta = document.createElement('div');
    pregunta.classList.add('Q4Y-question-row', 'd-flex');
    pregunta.innerHTML = `
            <button class="Q4Y-votes btn d-flex flex-column justify-content-start align-items-center" onclick="votar(event)" data-id-pregunta="${id}">
            <i class="bi no-voted bi-heart"></i>
            <div class="Q4Y-votes-number">
                <p class="small mb-0">
                    <span class="numberofvotes text-center">${votes}</span> votos
                </p>
            </div>
        </button>
        <div class="Q4Y-question">
            <div class="Q4Y-question-text">
                <p class="text-left">
                    ${question}
                </p>
            </div>
            <div class="Q4Y-question-for">
                <p class="small text-left mb-0">
                    <span class='strong'>Para: ${speaker}</span>
                </p>
            </div>
        </div>
    `;
    const Q4U_box = document.querySelector('#Q4Y-questions-box');
    Q4U_box.append(pregunta);
    sortByVotes();
}

export const enviarPregunta = async () => {
    const ponente = document.querySelector('#speaker');
    const nombre = document.querySelector('#name-q4y');
    const pregunta = document.querySelector('#question');
    const videochat = document.querySelector('#videochat');

    const requiredInput = [
        await checkIfInputIsRequiredAndIsEmpty(ponente),
        await checkIfInputIsRequiredAndIsEmpty(nombre),
        await checkIfInputIsRequiredAndIsEmpty(pregunta),
        await checkIfInputIsRequiredAndIsEmpty(videochat)
    ]

    if (requiredInput.includes(true)) {
        return;
    }
 
    const info = await decryptUserInfo();

    const data = new FormData();
    data.append('idevento', info.evento.id);
    data.append('idsala', info.sala.idsala);
    data.append('idusuario', info.usuario.id);
    data.append('tipo', info.sala.roomtype);
    data.append('pregunta', pregunta.value);
    data.append('nombre_sala', info.sala['nombre_sala'])
    data.append('key', '7be9406f1ee269b2de5c8ec86558b6d3')
    if (ponente) {
        data.append('ponente', ponente.value);
    }
    if (nombre) {
        data.append('nombre', nombre.value);
    }
    if (videochat) {
        data.append('videochat', isChecked(videochat));
    }

    const requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow',
        mode: 'cors'
    };

 

    fetch("https://backoffice.vconnect.tv/preguntas-al-ponente/API/nuevapregunta.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result === 'correcto') {
                    resetInput(ponente);
                    resetInput(nombre);
                    resetInput(pregunta);
                    resetInput(videochat);
                    let feedback = new PopUpAlert({message: dictionary[info.evento.lang].preguntaEnviada, type:'ok', auto:true});
                    feedback.new();
                } else {
                    let feedback = new PopUpAlert({message: dictionary[info.evento.lang].preguntaNoEnviada, type:'error', auto:false});
                    feedback.new();
                    console.error(result)
                }
            })
            .catch(error => {
                let feedback = new PopUpAlert({message: dictionary[info.evento.lang].preguntaNoEnviada, type:'error', auto:false});
                feedback.new();
                console.error('New Error, handle: ', error)
            });

}

export const votar = async (e) => {
    let botonVotar = getButton(e);
    let info = await decryptUserInfo();
    let idpregunta = botonVotar.dataset.idPregunta;
    let icon = botonVotar.querySelector('i');
    let accion = null;

    if(icon.classList.contains('no-voted')) {
        accion = 'sumar';
    } else {
        accion = 'restar';
    }

    const data = new FormData();

    data.append('idevento', info.evento.id );
    data.append('idsala', info.sala.idsala);
    data.append('idusuario', info.usuario.id);
    data.append('tipo', info.sala.roomtype);
    data.append('idpregunta', idpregunta);
    data.append('accion', accion);
    data.append('key', '7be9406f1ee269b2de5c8ec86558b6d3')

    const requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow',
        mode: 'cors'
    };


    fetch("https://backoffice.vconnect.tv/preguntas-al-ponente/API/votar.php", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result === 'correcto') {
                if (accion === 'sumar') {
                    icon.classList.remove('no-voted', 'bi-heart');
                    icon.classList.add('voted', 'bi-heart-fill');
                } else if (accion === 'restar') {
                    icon.classList.remove('voted', 'bi-heart-fill');
                    icon.classList.add('no-voted', 'bi-heart');
                }
            } else {
                let feedback = new PopUpAlert({message: dictionary[info.evento.lang].errorEnviandoVoto, type:'ok', auto:true});
                feedback.new();
                console.error(result);
            }
        })
        .catch(error => {
            let feedback = new PopUpAlert({message: dictionary[info.evento.lang].errorEnviandoVoto, type:'ok', auto:true});
            feedback.new();
            console.error('New Error, handle: ', error)
        });
    
    }

