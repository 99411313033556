import { loadCSRF } from "./main";

/**
 * Variables that must be available globally
 */

export const publicPusherKey = 'e744b89240f7a6006f00';
export const csrf = loadCSRF(); //CSRF Token Used with every transaction from the front to the back
let userHasInteracted = false;
let userPausedVideo = false;
export const body = document.querySelector('body');

window.userPausedVideo = userPausedVideo;
window.userHasInteracted = userHasInteracted;
window.body = body;