const IT = {
    omitir: 'Saltare',
    enviarRespuesta: 'Invia risposta',
    respuestaCorrecta: 'Risposta corretta! 🎉',
    respuestaIncorrecta: 'Risposta sbagliata 😓',
    respuestaGuardada: 'Abbiamo salvato la tua risposta ✅',
    error: 'Ops! Qualcosa non ha funzionato correttamente ⚠️',
    siguiente: 'Prossimo',
    mi: 'me',
    para: 'per',
    de: 'Da',
    ti: 'te',
    todos: 'tutti',
    enviarA: 'Inviare a:',
    required: 'Non può essere vuoto',
    preguntaEnviada: 'La pregunta se ha enviado correctamente',
    preguntaNoEnviada: 'Ha ocurrido un error enviando la pregunta',
    errorEnviandoVoto: 'Lo sentimos, no hemos podido enviar el voto'
}

export default IT;