import { csrf } from "../variables";
import { dictionary } from "../Dictionary/main";
import { decryptUserInfo } from "../main";
import { checkSala } from "./Realtime";


export const initChat = async (channel, info) => {
    const recipient = document.querySelector('#recipient');
    if (recipient) {
        await bindChat(channel, info);
        chatAutoScroll();
        pressEnterToSend();
        addEmojis();
        autoAdjustChatToFooterListeners();
    }
}

const bindChat = (channel, info) => {
    channel.bind('chat', (data) => {
        if (checkPrivate(data, info) && checkSalaAndEquipoChat(data, info)) {
            renderMessage(data, info);
            notification();
        }
    })
}

const bindEncryptChat = (channel, info) => {
    channel.bind('chat', (data) => {
        if (checkPrivate(data, info) && checkSalaAndEquipoChat(data, info)) {
            renderMessage(data, info);
            notification();
        }
    })
}

const checkPrivate = (data, info) => {
    let userID = parseInt(info.usuario.id);
    let privateID = parseInt(data.private);
    let senderID = parseInt(data.idusuario);
    switch (privateID) {
        case 0:
            return true;
        case userID:
            return true;
        default:
            if (userID == senderID) {
                return true
            }
            return false;
    }
}

const checkSalaAndEquipoChat = (data, info) => {
    let filter = info.evento.chat.filter;
    let equipo = info.usuario.equipo;
    switch (filter) {
        case 'open':
            return true;
        case 'team':
            return equipo == data.equipo ? true : false;
        case 'rooms':
            return checkSala(data, info);
    }
}

export const presenceSucceededChat = (members) => {
    if (document.querySelector('select#recipient')) {
        delete members.members[members.myID];
        for (let id in members['members']) {
            if(parseInt(id) === 0) continue;
            const member = {
                id: members['members'][id]['actionID'],
                name: members['members'][id]['name'],
                surname: members['members'][id]['surname'],
                mail: members['members'][id]['mail'],
            }
            appendMemberInChat(member)
        }
    }
}

export const presenceAddedChat = (data) => {
    if (document.querySelector('select#recipient')) {
        const member = processPresenceMemberOnChat(data);
        appendMemberInChat(member);
    }
}

export const presenceRemovedChat = (data) => {
    if (document.querySelector('select#recipient')) {
        const member = processPresenceMemberOnChat(data);
        removeMemberInChat(member);
    }
}

const appendMemberInChat = async (member) => {
    const renderedOption = await renderOptionForChat(member);
    document.querySelector('select#recipient').append(renderedOption);
}

const renderOptionForChat = async (member) => {
    const vconnect = await decryptUserInfo();
    const lang = vconnect.evento.lang;
    const {
        id,
        name,
        surname,
    } = member;
    const option = document.createElement('option');
    option.value = `${id}#${name} ${surname}`;
    option.dataset.chat = id;
    option.dataset.name = `${name} ${surname}`;
    option.innerHTML = `${dictionary[lang]['enviarA']} ${name} ${surname}`;
    return option;
}

const processPresenceMemberOnChat = (data) => {
    return {
        id: data.id,
        name: data.info.name,
        surname: data.info.surname,
        mail: data.info.mail
    }
}

const removeMemberInChat = (member) => {
    const {
        id,
    } = member;
    const node = document.querySelector(`[data-chat="${id}"]`);
    if (node) {
        node.remove();
    }
}



export const submitMessage = () => {
    const inputMessage = document.querySelector('.input-message')
    const recipient = document.querySelector('#recipient');
    if (inputMessage.innerText == '') {
        return;
    }

    let data = new FormData();
    data.append('message', inputMessage.innerText);
    data.append('private', recipient.value);

    let myHeaders = new Headers();
    myHeaders.append('X-CSRF-Token', csrf);

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: data
    }

    fetch('API/post.php?chat=submit', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result == true) {
                inputMessage.innerText = '';
            }
        });
}

const renderMessage = (data, info) => {
    const channelBody = document.querySelector('.public.channel .chat.body');
    let lang = info.evento.lang;

    let unixtimestamp = Date.now();
    let date = new Date(unixtimestamp);
    let hours = date.getHours();
    let minutes =  "0" + date.getMinutes();
    minutes = minutes.substring(minutes.length - 2 ,minutes.length)
    let timestamp_val = hours + ':' + minutes;

    let options = processMessageStyle(data, info);

    const message = document.createElement('div');
    message.classList.add('message', options.style);

    const name = document.createElement('p');
    name.classList.add('name');
    const privateName = document.createElement('span');
    privateName.classList.add(options.style2);
    privateName.innerHTML = options.privateName;
    name.innerHTML = `${dictionary[lang]['de']} ${options.name} ${dictionary[lang]['para']} `;
    name.append(privateName);

    const text = document.createElement('p');
    text.classList.add('text');
    text.innerText = data['message'];

    const timestamp = document.createElement('span');
    timestamp.classList.add('timestamp');
    timestamp.innerText = timestamp_val;

    message.append(name);
    message.append(text);
    message.append(timestamp);

    channelBody.append(message);
    chatAutoScroll();
}

const processMessageStyle = (data, info)=>{
    let lang = info.evento.lang;
    let userID = parseInt(info.usuario.id);
    let name = (parseInt(data.idusuario) == userID ? dictionary[lang]['mi'] : data.name);
    let privateName;
    let style = (parseInt(data.idusuario) == userID ? 'me' : 'empty');
    let style2 = (parseInt(data.private) == userID ? 'me' : 'empty');



    switch (parseInt(data.private)) {
        case parseInt(info.usuario.id):
            privateName = dictionary[lang]['ti'];
            break;
        case 0:
            privateName = dictionary[lang]['todos'];
            break;
        default:
            privateName = data['private_name'];
            break;
    }

    return {
        name: name,
        privateName: privateName,
        style: style,
        style2: style2
    }
}

export const toggleCollapseInChat = (e) => {
    e.parentElement.classList.toggle('collapse');
    if (!e.parentElement.classList.contains('collapse')) {
        e.querySelector('.expand .material-icons').innerText = 'expand_more';
    } else {
        e.querySelector('.expand .material-icons').innerText = 'expand_less';
    }
    document.querySelector('.wrapper').classList.toggle('wrapper-streaming')
    e.classList.remove('notification');
    chatAutoScroll();
}

export const toggleCollapseInEmojis = (e) => {
    let menuEmojis = e.parentElement.parentElement.querySelector('.menu-emojis');
    menuEmojis.classList.toggle('collapse');
}

const chatAutoScroll = () => {
    let chatBody = document.querySelector('.public.channel .chat.body');
    if (chatBody) {
        let scrollHeight = chatBody.scrollHeight;
        chatBody.scrollTop = scrollHeight;
    }
}

const pressEnterToSend = ()=>{
    const inputMessage = document.querySelector('.input-message')
    const channelBody = document.querySelector('.public.channel .chat.body');
    if(channelBody){
        document.addEventListener('keypress', function(e){
            if(document.activeElement === inputMessage && e.code == 'Enter'){
                e.preventDefault();
                submitMessage();
                inputMessage.blur();
                setTimeout(()=>{
                    inputMessage.focus();
                }, 500)
            }
        })
    }
}

const addEmojis = () => {
    const inputMessage = document.querySelector('.input-message')
    const channelBody = document.querySelector('.public.channel .chat.body');
    if(channelBody){
        const menuEmojis = document.querySelector('.public.channel .menu-emojis');
        menuEmojis.addEventListener('click',function(e){
            if(e.target.classList.contains('emoji')){
                let emoji = document.createElement('span');
                emoji.innerHTML = e.target.innerHTML;
                inputMessage.appendChild(emoji);
            }
        })
    }
}

const notification = () => {
    let chat = document.querySelector('.public.channel');

    if(chat.classList.contains('collapse')){
        chat.querySelector('header').classList.add('notification')
    }
}

const autoAdjustChatToFooterListeners = () => {
    let chat = document.querySelector('.public.channel');
    if(chat){
        autoAdjustChatToFooter();
        document.addEventListener('scroll', autoAdjustChatToFooter);
        document.addEventListener('resize', autoAdjustChatToFooter);
    }
}

const autoAdjustChatToFooter = () => {

    let chat = document.querySelector('.public.channel');
    let footer = document.querySelector('.footer');
    let height = 0;
    height = (window.scrollY + window.innerHeight) - footer.offsetTop;
    if((window.scrollY + window.innerHeight) >= footer.offsetTop) {
        chat.style.height = `calc(100vh - ${height}px)`;
        chat.style.marginBottom = `${height}px`;
    } else if ((window.scrollY + window.innerHeight) < footer.offsetTop) {
        chat.style.height = '';
        chat.style.marginBottom = '';
    }
}