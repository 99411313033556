import { iOScompatibility } from "./iOSCompatibility";
import { eventListeners } from "./eventListeners";
import { loadWowzaPlayer } from "./Players/Wowza";
import { initRealTime } from "./Realtime/Realtime";
import { csrf } from "./variables";
import { intervaloRegistrarActividad, registrarActividad } from "./RegistroActividad";
import { dictionary } from "./Dictionary/main";
import { loadMillicast } from "./Players/Millicast";
/**
 * En este archivo se encuentra las funciones de uso general y la función de inicialización de la aplicación
 */


export const initApp = () => {
    translate();
    iOScompatibility();
    eventListeners();
    loadWowzaPlayer();
    loadMillicast();
    initRealTime();
    intervaloRegistrarActividad = registrarActividad();
}


/**
 *  Add capability to translate the webpage
 */
const translate = () => {
    const trigger = document.querySelectorAll('[data-translate]');
    if (trigger) {
        trigger.forEach(element => {
            element.addEventListener('click', function () {
                let language = element.dataset.translate;
                var myHeaders = new Headers();
                myHeaders.append("X-CSRF-Token", csrf);

                var formdata = new FormData();
                formdata.append("language", language);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };
                fetch("API/post.php?evento=translate", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result === true) location.reload();
                    })
                    .catch(error => console.log('error', error));
            })
        })
    }
}



export const loadCSRF = () => {
    try {
        return document.querySelector('#csrf').value;
    } catch (error) {
        console.error('Can\'t find CSRF, handle: ' + error)
        return null;
    }
}

export const isChecked = (checkBox) => {
    if (checkBox.checked == true) {
        return 1;
    }
    return 0;
}

export const getUserfromSessionStorage = () => {
    if (typeof csrf !== 'undefined') {
        return JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('usuario'), csrf).toString(CryptoJS.enc.Utf8));
    } else {
        return JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('usuario'), 'pinyacolada').toString(CryptoJS.enc.Utf8));
    }
}

export const getTipodeSala = (usuario) => {
    if (usuario.idsala === null || (usuario.idsala == '0' && usuario.videoconferencia != null)) {
        return 'videoconferencia';
    } else {
        return 'streaming';
    }
}

export const getSala = (usuario) => {
    if (usuario.idsala === null || (usuario.idsala == '0' && usuario.videoconferencia != null)) {
        return usuario.videoconferencia;
    } else {
        return usuario.idsala;
    }
}

export const getButton = (e) => {
    if (e.target.tagName === 'BUTTON') {
        return e.target;
    } else if (e.target.parentNode.tagName === 'BUTTON') {
        return e.target.parentNode;
    }
}

export const encryptUserInfo = async (info) => {
    sessionStorage.setItem('vconnect', CryptoJS.AES.encrypt(JSON.stringify(info), csrf));
}

export const decryptUserInfo = async () => {
    return JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('vconnect'), csrf).toString(CryptoJS.enc.Utf8));
}

export const contarCaracteres = (inputID) => {
    const inputElement = document.querySelector(`#${inputID}`);
    const counter = document.querySelector(`[data-counter=${inputID}]`)
    if(inputElement && counter){
        inputElement.addEventListener('input', ()=> {
            let length = inputElement.value.length;
            let maxLength = inputElement.maxLength;
            counter.innerText = maxLength - length;
        })
    }
}

export const checkIfInputIsRequiredAndIsEmpty = async (input) => {
    if (input && input.required && isEmpty(input.value)) {
        if(!input.classList.contains('is-invalid')){
            let info = await decryptUserInfo()
            let error = document.createElement('div');
            error.innerHTML = dictionary[info.evento.lang].required;
            error.classList.add('invalid-feedback');
            if(!input.parentNode.querySelector('.invalid-feedback')){
                input.parentNode.appendChild(error);
            }
            input.classList.add('is-invalid');
        }
        return true;
    }
    return false;
}

export const isEmpty = (str) => {
    return (!str || str.length === 0);
}

export const resetInput = (input) => {
    if(input) {
        input.value = '';
        input.classList.remove('is-invalid')
        return true;
    }
    return false;
}