import Notification from './Notification';

/**
 * This class generate a new PopUp with the method new()
 * 
 * @returns HTMLDivContent. The container of where you're going to include your content
 * 
 */


class PopUp extends Notification {

    constructor() {
        super();
        this.embedContent = document.createElement('div');
        this.popUp = document.createElement('div');
    }

    new() {
        super.generateBackgroundForPopUp();
        super.bodyOverflowHidden();
        this.structure();
        super.appendInBody();
        this.animateIn();
        return this.embedContent;
    }

    structure() {
        try {
            this.popUp.classList.add('popup');

            this.embedContent.classList.add('container-fluid');

            let topbar = document.createElement('div');
            topbar.classList.add('row');
            topbar.innerHTML = "<div class='col d-flex justify-content-end align-items-center'><span class='material-icons md-48 close'  data-action='close-popup'>close</span></div>";

            this.popUp.append(topbar);
            this.popUp.append(this.embedContent);
            this.background.append(this.popUp);

            return true;
        } catch (error) {
            console.error(error);
            return false;
        }


    }

    animateIn(){
        setTimeout(()=>{
            this.popUp.classList.add('popupin');
        }, 300)
        return true;
    }




}



export {PopUp}