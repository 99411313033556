import { decryptUserInfo, initApp } from "./main";
import { stopRealTime } from "./Realtime/Realtime";
/**
 * 
 * @param {string} url 
 * @param {string} param 
 * @param {int} id 
 * 
 */
const toLink = async (url, param = null, id = null) => {

    try {
        let container = document.querySelector('#root');

        let historyURL = url;
    
        let requestURL = `${url}?SPA=true`;
        if(param !== null && id !== null) {
            requestURL += `&${param}=${id}`;
            historyURL += `?${param}=${id}`;
        }


        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        }
        
        let content = await fetch(requestURL, requestOptions);
        content = await content.text();
        container.innerHTML = content;

        history.pushState('', historyURL, historyURL)

        stopRealTime();
        initApp();
    } catch (error) {
        console.error(error);
    } 
   
}


window.toLink = toLink;

export {toLink};