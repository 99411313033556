const FR = {
    omitir: 'Sauter',
    enviarRespuesta: 'Envoyer la réponse',
    respuestaCorrecta: 'Bonne résponse! 🎉',
    respuestaIncorrecta: 'Mauvaise réponse  😓',
    respuestaGuardada: 'Nous avons enregistré votre réponse ✅',
    error: 'Oups! Quelque chose n\'a pas fonctionné correctement ⚠️',
    siguiente: 'Prochain',
    mi: 'moi',
    para:'pour',
    de: 'De',
    ti: 'toi',
    todos: 'tous',
    enviarA: 'Envoyer à:',
    required: 'Ne peut pas être vide',
    preguntaEnviada: 'La pregunta se ha enviado correctamente',
    preguntaNoEnviada: 'Ha ocurrido un error enviando la pregunta',
    errorEnviandoVoto: 'Lo sentimos, no hemos podido enviar el voto'
}


export default FR;