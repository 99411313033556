const DE = {
    omitir: 'Überspringen',
    enviarRespuesta: 'Antwort senden',
    respuestaCorrecta: 'Richtige antwort! 🎉',
    respuestaIncorrecta: 'Es war nicht richtig 😓',
    respuestaGuardada: 'Wir haben deine Antwort gespeichert ✅',
    error: 'Hoppla! Etwas hat nicht richtig funktioniert ⚠️',
    siguiente: 'Nächste',
    mi: 'mich',
    para: 'für',
    de: 'Von',
    ti: 'dich',
    todos: 'alle',
    enviarA: 'Senden an:',
    required: 'Kann nicht leer sein',
    preguntaEnviada: 'La pregunta se ha enviado correctamente',
    preguntaNoEnviada: 'Ha ocurrido un error enviando la pregunta',
    errorEnviandoVoto: 'Lo sentimos, no hemos podido enviar el voto'
}

export default DE;