const PT =  {
    omitir: 'Pular',
    enviarRespuesta: 'Enviar resposta',
    respuestaCorrecta: 'Resposta correta! 🎉',
    respuestaIncorrecta: 'Resposta incorreta 😓',
    respuestaGuardada: 'Nós salvamos sua resposta ✅',
    error: 'Ops! Algo não funcionou corretamente ⚠️',
    siguiente: 'Próximo',
    mi: 'mim',
    para:'para',
    de: 'De',
    ti: 'você',
    todos: 'todos',
    enviarA: 'Enviar para:',
    required: 'Não pode estar vazio',
    preguntaEnviada: 'La pregunta se ha enviado correctamente',
    preguntaNoEnviada: 'Ha ocurrido un error enviando la pregunta',
    errorEnviandoVoto: 'Lo sentimos, no hemos podido enviar el voto'
}

export default PT;