import { PopUp } from "../Notification/PopUp";
import { checkSegmentacion, checkSala, removePopUp, exitFullScreen } from "./Realtime";

export const bindPopUp = async (channel, info) => {
    channel.bind('Pop-Up', (data) => {
        if (checkSegmentacion(data, info) && checkSala(data, info)) {
            if (data.activo == 0) {
                removePopUp();
            } else if (data.activo == 1) {
                removePopUp();
                setTimeout(() => {
                    renderPopUp(data, info.evento.lang)
                }, 400)
            }
        }
    })
}

const renderPopUp = (data, lang) => {
    exitFullScreen();
    let descripcion_language = 'descripcion';
    if(lang !== 'ES'){
        descripcion_language = `${descripcion_language}_${lang}`;
    }
    const embedContent = new PopUp().new();

    if(data[descripcion_language] == undefined || data[descripcion_language] == ''){
        embedContent.innerHTML = data['descripcion'];
    } else {
        embedContent.innerHTML = data[descripcion_language];
    }

    if(data['idsiguiente'] != 0) {
        const rowButtons = document.createElement('div');
        rowButtons.classList.add('row');

        const colButtons = document.createElement('div');
        colButtons.classList.add('col', 'd-flex', 'justify-content-end');

        const next = document.createElement('button');
        next.classList.add('btn', 'btn-plain', 'mt-4');
        next.id = 'btn-next';
        next.dataset.role = 'button';
        next.innerText = dictionary[lang]['siguiente'];

        rowButtons.append(colButtons);
        colButtons.append(next);
        embedContent.append(rowButtons);

        next.addEventListener('click', () => {
            loadNextPopUp(data['idsiguiente'], lang);
        });
    }
}

const loadNextPopUp = async (idPopup, lang) => {
    let data = await fetchPopUp(idPopup);
    removePoll()
    .then(
        setTimeout(() => {
            renderPopUp(data, lang)
        }, 400)
    )
}

const fetchPopUp = async (idPopup) => {
    var myHeaders = new Headers();
    myHeaders.append("X-CSRF-Token", csrf);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    let popup = await fetch(`API/get.php?popup=get_popup&id=${idPopup}`, requestOptions);
    popup = await popup.json();
    return popup;
}