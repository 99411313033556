/**
 * Add some functions to improve compatibility with iOS and iPadOS
 */

const iOScompatibility = () => {
    if(is_iOS()){
        disallowFullscreen();
    }
}


const is_iOS = () => {
    const useragent = navigator.userAgent;
    const regexiPad = /iPhone|iPad/gmi;
    if((useragent.match(regexiPad)) || /Macintosh/i.test(useragent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1){
        return true;
    }
    return false;
}

const is_Millicast = (src) => {
    const regex = /millicast/gmi;
    return src.match(regex);
}

const disallowFullscreen = () => {
    const iframes = document.querySelectorAll('iframe');

    iframes.forEach(iframe => {
        let container = iframe.parentElement;
        let src = iframe.src;

        if(is_Millicast(src)){
            let newFrame = document.createElement('iframe');
            container.appendChild(newFrame);
            blockFullScreenMillicast(container);
        }
    })

    const millicastPlayer = document.querySelector('#millicast');
    if(millicastPlayer){
        let container = millicastPlayer.parentElement;
        blockFullScreenMillicast(container);
    }
}

const blockFullScreenMillicast = (container) => {
    let blockFullScreen = document.createElement('div');
    blockFullScreen.classList.add('block-fullscreen');
    container.appendChild(blockFullScreen);

    let blockFullScreenBottomRight = document.createElement('div');
    blockFullScreenBottomRight.classList.add('block-fullscreen-bottom-right');
    container.appendChild(blockFullScreenBottomRight);
}

export {iOScompatibility, is_iOS}