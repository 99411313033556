import { PopUp } from "./PopUp";

/**
 * 
 * @param {message?: string, type?: string, auto?: boolean} props
 * 
 * Message: specify the message that you want to show
 * 
 * Type: There are two types of icons predefined: 'ok' or 'error'
 * 
 * If you don't specify the props above described, the pop-up would be empty and you can fill it with other content
 * 
 * Auto: Specify if the popup should close automatically or not
 * 
 * @return the embedContainer if you don't specify message and type
 */

 class PopUpAlert extends PopUp {

    constructor(props = {
        message: '',
        type: '',
        auto: true
    }) {
        super();
        const {
            message,
            type,
            auto
        } = props;

        this.message = message;
        this.type = type;
        this.auto = auto;

    }

    new() {
        super.generateBackgroundForPopUp();
        super.bodyOverflowHidden();
        super.structure();
        this.alert();
        this.autoClose();
        super.appendInBody();
        super.animateIn();
        return true;
    }

    alert() {
        try {
            let icon = null;
            switch (this.type) {
                case 'ok':
                    icon = '<span class="material-icons ok"> check_circle</span>';
                    break;
                case 'error':
                    icon = '<span class="material-icons error">error</span>';
                    break;
                default:
                    icon = '';
                    break;
            }
            this.embedContent.classList.add('d-flex', 'flex-column', 'justify-content-center', 'align-items-center', 'text-center');
            this.embedContent.innerHTML = `${icon} <h3 class="text-center">  ${this.message} </h3>`;
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }

    }

    autoClose() {
        if (this.auto) {
            setTimeout(() => {
                this.embedContent.classList.remove('popupin');
                setTimeout(() => {
                    this.background.remove();
                }, 2000)
            }, 2300)
        }
    }

}

/**
 * Legacy Pop-up
 * 
 * @param {string} msg 
 * @param {string} type 
 * @param {boolean} auto 
 * @param {boolean} Alert 
 * 
 */

const popUpAlert = (msg = null, type = null, auto = true, Alert = true) => {
    document.body.style.overflow = 'hidden';

    let velo = document.createElement('div');
    velo.id = 'velo';

    let popUp = document.createElement('div');
    popUp.classList.add('popup');

    let embedContent = document.createElement('div');
    embedContent.classList.add('d-flex', 'flex-column', 'justify-content-center', 'align-items-center', 'text-center', 'embedContent');
    embedContent.style.width = '100%';

    let topbar = document.createElement('div');
    topbar.classList.add('row');
    topbar.innerHTML = "<div class='col d-flex justify-content-end align-items-center'><span class='material-icons md-48 close'  data-action='close-popup'>close</span></div>";

    if (Alert) {
        let icon;
        switch (type) {
            case 'ok':
                icon = '<span class="material-icons ok"> check_circle</span>';
                break;
            case 'error':
                icon = '<span class="material-icons error">error</span>';
                break;
        }
        embedContent.innerHTML = icon + '<h3 class="text-center">' + msg + '</h3>';
    }

    popUp.append(topbar);
    popUp.append(embedContent);
    velo.append(popUp);
    document.querySelector('body').append(velo);


    setTimeout(function () {
        popUp.classList.add('popupin');
    }, 10)

    if (auto) {
        setTimeout(() => {
            popUp.classList.remove('popupin');
            document.body.style.overflow = 'auto';
        }, 2000)
        setTimeout(() => {
            velo.remove();
        }, 2300)
    }
}

export {PopUpAlert, popUpAlert}