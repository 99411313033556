const CAT = {
    omitir: 'Ometre',
    enviarRespuesta: 'Enviar resposta',
    respuestaCorrecta: 'Resposta correcta! 🎉',
    respuestaIncorrecta: 'Resposta incorrecta 😓',
    respuestaGuardada: 'Hem guardat la teva resposta ✅',
    error: 'Ups! Alguna cosa no ha funcionat correctament ⚠️',
    siguiente: 'Següent',
    mi: 'mi',
    para: 'per a',
    de: 'de',
    ti: 'tu',
    todos: 'tots',
    enviarA: 'Enviar a',
    required: 'No pot estar buit',
    preguntaEnviada: 'La pregunta se ha enviado correctamente',
    preguntaNoEnviada: 'Ha ocurrido un error enviando la pregunta',
    errorEnviandoVoto: 'Lo sentimos, no hemos podido enviar el voto'
}

export default CAT;