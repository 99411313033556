import { presenceAddedChat, presenceRemovedChat, presenceSucceededChat } from "./Chat";
import { publicPusherKey, csrf } from "../variables";

export const connectToPresenceChannel = async (info) =>{
    //Pusher.logToConsole = true;
    var pusher = new Pusher(publicPusherKey, {
        authEndpoint: 'API/post.php?realtime=presence_auth',
        cluster: 'eu',
        auth: {
            headers: {
                "X-CSRF-Token": csrf
            }
        }
    })
    return pusher.subscribe(`presence-${info.evento.id}`);
}

export const bindPresence = async (channel) => {
    channel.bind("pusher:subscription_succeeded", (members) => {
        presenceSucceededChat(members);
    });
    channel.bind("pusher:member_added", (data) => {
        presenceAddedChat(data);
    })
    channel.bind("pusher:member_removed", (data) => {
        presenceRemovedChat(data);
    })
}