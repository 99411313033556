import { is_iOS } from "../iOSCompatibility";

class Notification {

    constructor(){
        this.background = document.createElement('div');
        this.body = document.querySelector('body');
    }

    appendInBody(){
        try{
            body.append(this.background);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
        
    }

    generateBackgroundForPopUp() {
        try {
            this.background.id = 'velo';
            if(is_iOS()){
                this.background.classList.add('iphone');
            }
            body.append(this.background);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }

    }

    bodyOverflowHidden() {
        try{
            document.querySelector('body').style.overflow = 'hidden';
            return true;
        } catch (error) {
            return false;
        }

    }
}

export default Notification;