/**
 * Funciones relacionadas con las Cookies en la plataforma
 * 
 * @returns string\boolean
 */

const leerCookie = (nombre) => {
    let cookies = document.cookie.split(';');
    let micookie;
    for (let i in cookies) {
        if (cookies[i].search(nombre) > -1) {
            micookie = cookies[i];
        }
    }
    if (micookie) {
        let igual = micookie.indexOf("=");
        return micookie.substring(igual + 1);
    }
    return false;
}

window.leerCookie = leerCookie;