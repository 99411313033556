const loadWowzaPlayer = () => {
    const container = document.querySelector('#wowzaPlayer');
    if(container){
        WowzaPlayer.create("wowzaPlayer",{
            "license": "PLAY2-cbm8T-R8xfk-njeeY-AD9fZ-KUw64",
            "sources": [{
                    "sourceURL": container.dataset.source
                },
                {
                    "sourceURL": container.dataset.backup
                }
            ],
            "title": "",
            "description": "",
            "autoPlay": container.dataset.autoplay,
            "mute": container.dataset.mute,
            "volume": 75,
            "posterFrameURL": container.dataset.poster
        })
        console.log(WowzaPlayer);
    }
    
}

export {loadWowzaPlayer}