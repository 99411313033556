import {checkSegmentacion,checkSala} from "./Realtime";

export const bindUsersControl = (channel, info) => {
    channel.bind('UsersControl', (data) => {
        if (checkSegmentacion(data, info) && (checkSala(data, info) || data.idsala==0)) {
            switch (data['accion']) {
                case 'expulsar':
                    window.location = 'logout';
                    break;
                case 'recargar':
                    if(data.roomtype == 'streaming' && info.sala.roomtype == 'videochat') return;
                    location.reload();
                    break;
                case 'mostrar':
                    document.querySelector(data.identificador).classList.remove('d-none');
                    break;
                case 'redirigir':
                    if (data.identificador != 'salas.php') {
                        let roomtype = data.identificador.split('-')[0];
                        let idsala = data.identificador.split('-')[1];
                        if (roomtype == 'streaming' && idsala == info.sala.idsala) {
                            return;
                        } else if (roomtype == 'videoconferencia' && idsala == info.sala.videoconferencia) {
                            return;
                        }
                    } else if (data.identificador == 'salas.php') {
                        let href = window.location.href.split('/').pop();
                        if (href === data.identificador) return;
                    }

                    window.location = data.identificador;
                    break;
                case 'reproducir video':
                    document.querySelector(data.identificador).play()
                    break;
                case 'pausar video':
                    document.querySelector(data.identificador).pause()
                    break;
                case 'boton-redireccion':
                    let seccionRedireccion = document.querySelector('#redireccion');
                    seccionRedireccion.classList.remove('d-none');
                    seccionRedireccion.style.transform = "translateY(0px)";
                    seccionRedireccion.querySelector('a').href = data.identificador;
                    break;
                case 'redirigir-externo':
                    window.open(data.identificador, '_blank');
                    break;
                case 'videoQ4U':
                    sessionStorage.setItem('idpregunta', data.idpregunta);
                    let redireccionVideoQ4U = document.querySelector('#alert-live');
                    redireccionVideoQ4U.classList.remove('d-none');
                    redireccionVideoQ4U.querySelector('a').href = `ask-on-live-${data.idpregunta}-${data.sala}`;

                    break;
                case 'insertar iframe Q4U':
                    let iframe = document.querySelector('#videoconferencia[data-section="Q4U"] iframe');
                    if (iframe) {
                        iframe.src = data.iframe;
                    }
                    break;

                case 'expulsar-videoQ4U':
                    history.back();
                    break;
            }
        }
    })
}