import './js/variables';
import './js/eventListeners';
import { submitMessage, toggleCollapseInEmojis, toggleCollapseInChat } from './js/Realtime/Chat';
import { toLink } from './js/navigation';

import './js/iOSCompatibility';
import './js/cookies';
import { initApp } from './js/main'

import './js/PWA';
import { enviarPregunta, votar } from './js/Realtime/PreguntasAlPonente';
import './scss/app.scss';
import { popUpAlert } from './js/Notification/PopUpAlert';

document.addEventListener('DOMContentLoaded', initApp);

window.toLink = toLink;
window.enviarPregunta = enviarPregunta;
window.votar = votar;
window.toggleCollapseInChat = toggleCollapseInChat;
window.toggleCollapseInEmojis = toggleCollapseInEmojis;
window.submitMessage = submitMessage;
window.popUpAlert = popUpAlert;