const EN = {
    omitir: 'Skip',
    enviarRespuesta: 'Submit answer',
    respuestaCorrecta: 'You got it right! 🎉',
    respuestaIncorrecta: 'Ops! Wrong answer 😓',
    respuestaGuardada: 'We have saved your answer ✅',
    error: 'Oops! Something has not worked correctly ⚠️',
    siguiente: 'Next',
    mi: 'me',
    para: 'for',
    de: 'From',
    ti: 'you',
    todos: 'all',
    enviarA: 'Send to:',
    required: 'Can\'t be empty',
    preguntaEnviada: 'La pregunta se ha enviado correctamente',
    preguntaNoEnviada: 'Ha ocurrido un error enviando la pregunta',
    errorEnviandoVoto: 'Lo sentimos, no hemos podido enviar el voto'
}

export default EN;