import CAT from './CAT'
import DE from './DE'
import ES from './ES'
import EN from './EN'
import FR from './FR'
import IT from './IT'
import POL from './POL'
import PT from './PT'

export const dictionary = {CAT, DE, ES, EN, FR, IT, POL, PT};
