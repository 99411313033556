import { decryptUserInfo, encryptUserInfo } from "../main";
import { connectToPresenceChannel, bindPresence } from "./PresenceChannel";
import { initChat } from "./Chat";
import { bindPolls } from "./Polls";
import { bindPopUp } from "./PopUp";
import { bindUsersControl } from "./ControlDeUsuarios";
import { csrf, publicPusherKey } from "../variables";
import { initEmociones } from "./Emociones";
import { initPreguntasAlPonente } from "./PreguntasAlPonente";

export let publicChannel = null;
export let presenceChannel = null;


export const initRealTime = async () => {
    const info = {
        usuario: await fetchUsuario(),
        evento: await fetchEvento(),
        sala: await fetchSala()
    }

    presenceChannel = await connectToPresenceChannel(info);
    await bindPresence(presenceChannel);

    publicChannel = await connectToPusher(info);
    await bindPolls(publicChannel, info);
    await bindPopUp(publicChannel, info);
    await bindUsersControl(publicChannel, info);

    await initChat(publicChannel, info);
    await initEmociones(publicChannel, info);
    await initPreguntasAlPonente(publicChannel, info);

    await encryptUserInfo(info);
}

export const stopRealTime = async () => {
    let info = await decryptUserInfo();
    let channel = info.evento.id;
    presenceChannel.unsubscribe(channel);
    publicChannel.unsubscribe(channel);
}

const fetchUsuario = async () => {
    var myHeaders = new Headers();
    myHeaders.append("X-CSRF-Token", csrf);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    let usuario = await fetch('API/get.php?usuario=get_info', requestOptions);
    usuario = await usuario.json();
    return usuario;
}

const fetchEvento = async () => {
    var myHeaders = new Headers();
    myHeaders.append("X-CSRF-Token", csrf);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    let evento = await fetch('API/get.php?evento=get_info', requestOptions);
    evento = await evento.json();
    return evento;
}

const fetchSala = async () => {
    var myHeaders = new Headers();
    myHeaders.append("X-CSRF-Token", csrf);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    let sala = await fetch('API/get.php?sala=get_info', requestOptions);
    sala = await sala.json();
    return sala;
}

const connectToPusher = async (info) => {
    Pusher.LogToConsole = false;
    let pusher = new Pusher(publicPusherKey, {
        cluster: 'eu'
    })
    return pusher.subscribe(`${info.evento.id}`)
}

export const checkSegmentacion = (data, info) => {
    if (data.segmentacion_val == info.usuario[data.segmentacion_col] || (data.segmentacion_col === 'all' || data.segmentacion_col == '')) {
        return true;
    }
    return false;
}

export const checkSala = (data, info) => {
    if ((data.idsala == info.sala.idsala && data.roomtype.toLowerCase() == info.sala.roomtype.toLowerCase()) || data.idsala == 0) {
        return true;
    }
    return false;
}

export const checkSalaPoll = (data, info) => {
    if ((data.idplano == info.sala.idsala && data.roomtype.toLowerCase() == info.sala.roomtype.toLowerCase()) || data.idplano == 0) {
        return true;
    }
    return false;
}

export const removePopUp = () => {
    let popUp = document.querySelector('#velo');
    if (popUp) {
        document.querySelector('.popup').classList.remove('popupin');
        setTimeout(() => {
            popUp.remove();
            body.style.overflow = 'auto';
            return Promise.resolve();
        }, 200)
    }
}

export const exitFullScreen = () => {
    if(document.fullscreenElement){
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }
}
